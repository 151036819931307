<template>
    <div class="header">
        <p @click="scrollToElement('dumpling')">Dumpling</p>
        <p @click="scrollToElement('kimchi')">Kimchi</p>
        <p @click="scrollToElement('condiment')">Condiment</p>
        <p @click="scrollToElement('herbalDrink')">Herbal Drink</p>
    </div>

    <div class="logo-container">
        <div class="logo"/>
    </div>

    <div class="full-background">
        <img src="./assets/images/main_banner.jpg">
    </div>
    <div class="text-container">
        <p class="title">HOMEMADE DUMPLING</p>
        <p class="paragraph">Complimentary Dipping Sauce: Kimchi & Vinegar Sauce for each set.</p>
    </div>

    <div class="show-case-container" ref="dumpling">
        <div class="show-case-image dumpling"></div>
        <div class="show-case-description">
            <p class="title" >Frozen Dumpling</p>
            <p>More than 10 types of Dumpling</p>
            <div 
            @click="scrollToElement('order')" 
            class="button"
            :class="isDumplingButtonHovered ? ' hovered' : ''"
            @mouseenter="isDumplingButtonHovered=true;"
            @mouseleave="isDumplingButtonHovered=false;">
                Order Now
            </div>
        </div>
    </div>
    <div class="show-case-container" ref="kimchi">
        <div class="show-case-image kimchi"></div>
        <div class="show-case-description">
            <p class="title">Tangy Kimchi</p>
            <p>Delicious sour-ish with a hint of sweetness</p>
            <div 
            @click="scrollToElement('order')"
            class="button"
            :class="isKimchiButtonHovered ? ' hovered' : ''"
            @mouseenter="isKimchiButtonHovered=true;"
            @mouseleave="isKimchiButtonHovered=false;">
                Order Now
            </div>
        </div>
    </div>
    <div class="show-case-container" ref="condiment">
        <div class="show-case-image condiment"></div>
        <div class="show-case-description">
            <p class="title">Chili Oil & Premium Mala Sauce</p>
            <div 
            @click="scrollToElement('order')"
            class="button"
            :class="isCondimentButtonHovered ? ' hovered' : ''"
            @mouseenter="isCondimentButtonHovered=true;"
            @mouseleave="isCondimentButtonHovered=false;">
                Order Now
            </div>
        </div>
    </div>
    <div class="show-case-container" ref="herbalDrink">
        <div class="show-case-image herbal-drink"></div>
        <div class="show-case-description">
            <p class="title">Herbal Drinks</p>
            <div 
            @click="scrollToElement('order')"
            class="button"
            :class="isHerbalDrinkButtonHovered ? ' hovered' : ''"
            @mouseenter="isHerbalDrinkButtonHovered=true;"
            @mouseleave="isHerbalDrinkButtonHovered=false;">
                Order Now
            </div>
        </div>
    </div>

    <div class="order-container" ref="order">
        <p class="title">Contact Us Now</p>
        <a :href="whatsappCatalogLink" target="_blank">
            <div 
            class="button large-button"
            :class="isOrderButtonHovered ? ' hovered' : ''"
            @mouseenter="isOrderButtonHovered=true;"
            @mouseleave="isOrderButtonHovered=false;">
                Whatsapp Us
            </div>
        </a>
    </div>

    <div class="footer">
        <div class="footer-content left">
            <p class="title">Klang Valley Delivery</p>
            <p>- Damansara, Petaling Jaya, Kuala Lumpur, Puchong, Bukit Jalil, Seri Kembangan, Subang Jaya, Klang, Shah Alam, Cheras, Sungai Buloh, Kepong, Desa Park City, Bandar Sri Damansara</p>
            <p>- Delivery Fee: Flat Rate RM15</p>
            <p>- Delivery Date: Every Wednesday & Saturday</p>
            <p>- Whatsapp us if any specific date & time for delivery or pickup</p>
            <p>- Self Pick-up available at Petaling Jaya, full address will be give once order confirmed</p>

            <p class="title">Terms & Conditions:</p>
            <p>- Goods Sold are not refundable/replace.</p>
            <p>- Order will only be confirmed after payment made.</p>
        </div>
        <div class="footer-content right">
            <a class="icon whatsapp" :href="whatsappChatLink" target="_blank"></a>
            <a class="icon instagram" :href="instagramLink" target="_blank"></a>
            <a class="icon facebook" :href="facebookLink" target="_blank"></a>
            <a class="icon shopee" :href="shopeeLink" target="_blank"></a>
            <a class="icon grab" :href="grabLink" target="_blank"></a>
        </div>
    </div>  

</template>

<script>

export default {
  name: 'App',
  components: {},
  data(){
    return{
      whatsappCatalogLink: "https://wa.me/c/60177786582",
      whatsappChatLink: "https://api.whatsapp.com/send?phone=60177786582",
      instagramLink: "https://www.instagram.com/myhappydumpling/",
      facebookLink: "https://www.facebook.com/MyHappyDumpling/",
      shopeeLink: "https://shopee.com.my/universal-link/now-food/shop/312909?deep_and_deferred=1&share&smtt=0.0.3",
      grabLink: "https://grab.onelink.me/2695613898?pid=inappsharing&c=1-C3KECCNYRB6DR6&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26sourceID%3DA4pcqCZkS4%26merchantIDs%3D1-C3KECCNYRB6DR6&af_force_deeplink=true&af_web_dp=https%3A%2F%2Fwww.grab.com",

      isDumplingButtonHovered: false,
      isKimchiButtonHovered: false,
      isCondimentButtonHovered: false,
      isHerbalDrinkButtonHovered: false,
      isOrderButtonHovered: false,
      initiateTimeout: "",
      endingTimeout: "",
      observer: "",
    }
  },
  methods:{
    scrollToElement: function(elementName){
        this.observer = "";
        this.isDumplingButtonHovered = false;
        this.isKimchiButtonHovered = false;
        this.isCondimentButtonHovered = false;
        this.isHerbalDrinkButtonHovered = false;
        this.isOrderButtonHovered = false;
        
        var element = "";
        var self = this;
        switch(elementName){
            case "dumpling":    {
                element = this.$refs.dumpling; 
                this.isDumplingButtonHovered = true; 
                setTimeout(() => {self.isDumplingButtonHovered = false;}, 1000); 
                break;
            }
            case "kimchi":      {
                element = this.$refs.kimchi; 
                this.isKimchiButtonHovered = true; 
                setTimeout(() => {self.isKimchiButtonHovered = false;}, 1000); 
                break;
            }
            case "condiment":   {
                element = this.$refs.condiment; 
                this.isCondimentButtonHovered = true; 
                setTimeout(() => {self.isCondimentButtonHovered = false;}, 1000); 
                break;
            }
            case "herbalDrink": {
                element = this.$refs.herbalDrink; 
                this.isHerbalDrinkButtonHovered = true; 
                setTimeout(() => {self.isHerbalDrinkButtonHovered = false;}, 1000); 
                break;
            }
            case "order":       {
                element = this.$refs.order; 
                this.isOrderButtonHovered = true; 
                setTimeout(() => {self.isOrderButtonHovered = false;}, 1000); 
                break;
            }
        }
        if(element != ""){
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
        }
    }
  },
  mounted(){
  }
}
</script>

<style>
:root {
    --color-dark: #252525;
    --color-light: #fff6eb;
    --color-highlight: #e18a19;
    --color-black: #2c2c2c;
    --color-white: #ffffff;

    --font-size-1: 12px;
    --font-size-2: 16px;
    --font-size-3: 20px;
    --font-size-4: 25px;
    --font-size-5: 32px;
    --font-size-6: 38px;
}

html{ height: 100%; width:100%; background-image: url("./assets/images/Base_Background.jpeg"); scroll-behavior: smooth; font-family: Arial, sans-serif; cursor: default}
body, p, a{ margin: 0;}
a{text-decoration: none;}

.button{ height: 50px; width: 300px; background-color: var(--color-dark); color: var(--color-light); font-size: 16px; border-radius: 30px; line-height: 50px; text-align: center; margin: 20px 0; transition: .3s; max-width: 100%; cursor: pointer;}
.button.large-button{ margin: 30px auto; height: 100px; width: 300px; line-height: 100px; font-size: 36px; border-radius: 60px; }
.button.hovered{ color: var(--color-dark); background-color: var(--color-highlight); box-shadow: 0 0 30px var(--color-black); }

.header{ position: sticky; top: 0; width: 100%; background-color: var(--color-white); display: flex; z-index: 10; align-items: center; justify-content: center;}
.header p{ color: var(--color-black); font-size: 28px; padding: 10px; position: relative; transition: .3s; text-decoration: none; }
.header p:hover{ font-size: 32px; background-image: radial-gradient(rgba(0,0,0,.3), rgba(0,0,0,.1), rgba(0,0,0,0)); }
.header p:not(:last-child)::after{ content: " "; display: block; position: absolute; top: 10%; right: -10px; height: 80%; width: 1px; background-color: var(--color-black); margin: 0 10px;}
.header .header-text{ font-family: "KG"; font-size: 2rem; margin: 0.1rem; line-height: 4rem; }

.logo-container{ display: flex; justify-content: center; align-items: center }
.logo-container .logo{ width: 30%; min-width: 350px; max-width: 500px; height: 10vw; max-height: 300px; min-height: 200px; margin: 30px; background-image: url("./assets/images/transparent_logo.png"); background-position: center; background-repeat: no-repeat; background-size: contain;}

.full-background{ aspect-ratio: 2/1; width: 90%; max-height: 50vh; display: flex; justify-content: center; padding: 0 5%; }
.full-background img{ aspect-ratio: 2/1; margin: 0 5%; height: 100%; max-width: calc(80vh + 10%); background-position: center; background-repeat: no-repeat; background-size: contain; position: relative; }

.text-container,
.order-container{ height: 100%; max-width: 800px; text-align: center; margin: 30px auto;}
.order-container a{width: 300px; margin: auto; display: block;}
.text-container *{ margin-left:  10px; margin-right:  10px; }
.text-container .title{ font-weight: 600; font-size: 30px; }
.text-container .paragraph{ font-weight: 400; font-size: 18px; }

.order-container{margin: 100px auto;}
.order-container .title{ font-weight: 600; font-size: 30px; }
.order-container .icon-container{ width: 100%; display: flex; justify-content: center; }

.show-case-container{ width: calc(90% - 60px); display: flex; flex-direction: row; align-items: center; justify-content: center; margin: 30px 5%; padding: 30px; border-radius: 50px; background-color: rgba(0,0,0,0.1); }
.show-case-container:nth-child(2n){ flex-direction: row-reverse }
.show-case-container .show-case-image{ aspect-ratio: 1/1; width: 45%; max-width: 350px; background-position: center; background-repeat: no-repeat; background-size: contain; margin-right: 5%; box-shadow: 0 0 30px var(--color-black); margin-top: 0 !important; margin-bottom: 0 !important; padding-top: 0 !important; padding-bottom: 0 !important; }
.show-case-container:nth-child(2n) .show-case-image{ margin-right: 0; margin-left: 5%; }
.show-case-container .show-case-image.kimchi       {background-image: url("./assets/images/product_kimchi.jpg");}
.show-case-container .show-case-image.dumpling     {background-image: url("./assets/images/product_dumpling.jpg");}
.show-case-container .show-case-image.condiment    {background-image: url("./assets/images/product_condiment.jpg");}
.show-case-container .show-case-image.herbal-drink {background-image: url("./assets/images/product_herbal_drink.jpg");}
.show-case-container .show-case-description{ display: flex; flex-direction: column; width: 50%; }
.show-case-container .show-case-description p{ font-size: 18px; }
.show-case-container .show-case-description p.title{ font-weight: 600; font-size: 30px; }

.footer{ background-color: var(--color-black); display: flex; width: 100%; color: var(--color-light); }
.footer .footer-content{ width: 50%; display: flex; flex-wrap: wrap; flex-direction: column; padding: 2%; }
.footer .footer-content.right{ flex-direction: row; }
.footer .footer-content p{ margin-bottom: 10px; }
.footer .footer-content p.title{ font-size: 24px; }
.footer .footer-content p.title:not(:first-child){ margin-top: 20px; }
.footer .footer-content .icon{ width: 50px; height: 50px; margin: 10px; background-position: center; background-repeat: no-repeat; background-size: contain; }
.footer .footer-content .icon.whatsapp    {background-image: url("./assets/images/icon_whatsapp.png");}
.footer .footer-content .icon.shopee      {background-image: url("./assets/images/icon_shopee.png");}
.footer .footer-content .icon.grab        {background-image: url("./assets/images/icon_grab.png");}
.footer .footer-content .icon.instagram   {background-image: url("./assets/images/icon_instagram.png");}
.footer .footer-content .icon.facebook    {background-image: url("./assets/images/icon_facebook.png");}

.header p,
.header p:hover{font-size: var(--font-size-4);}


.text-container .paragraph,
.show-case-container .show-case-description p,
.button{font-size: var(--font-size-4);}
.header p:hover{font-size: var(--font-size-5);}
.text-container .title,
.show-case-container .show-case-description p.title,
.order-container .title{font-size: var(--font-size-6);}

@media screen and (max-width: 480px) {
    .show-case-container{flex-direction: column !important;}
    .show-case-container .show-case-image{ width: 70vw; margin: auto !important; max-width: unset; }
    .show-case-container .show-case-description{align-items: center; width: 90%; text-align: center;}
    .header p,
    .header p:hover{font-size: var(--font-size-1);}
    .logo-container .logo{margin: 0; min-width: 300px;}

    .footer {flex-direction: column;}
    .footer .footer-content.left,
    .footer .footer-content.right{ width: 90%; margin: 20px auto;}
    .footer .footer-content.right{ justify-content: center;}
    .footer .footer-content .icon{margin: 5px; height: 40px; width: 40px;}

    .show-case-container .show-case-description p.title{margin-top: 30px;}
    .text-container .title,
    .show-case-container .show-case-description p.title,
    .order-container .title{font-size: var(--font-size-4)}
    .text-container .paragraph,
    .show-case-container .show-case-description p,
    .button{font-size: var(--font-size-3)}
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .show-case-container{flex-direction: column !important;}
    .show-case-container .show-case-image{ width: 60vw; margin: auto !important; max-width: unset; }
    .show-case-container .show-case-description{align-items: center; width: 90%; text-align: center;}
    
    .header p,
    .header p:hover{font-size: var(--font-size-3);}

    .logo-container .logo{margin: 0px; min-width: 350px;}

    .footer {flex-direction: column;}
    .footer .footer-content.left,
    .footer .footer-content.right{ width: 90%; margin: 20px auto;}
    .footer .footer-content.right{ justify-content: center;}

    .show-case-container .show-case-description p.title{margin-top: 30px;}
    .text-container .title,
    .show-case-container .show-case-description p.title,
    .order-container .title{font-size: var(--font-size-5)}
    .text-container .paragraph,
    .show-case-container .show-case-description p,
    .button{font-size: var(--font-size-4)}
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .show-case-container .show-case-image{max-width: 250px !important;}
    .logo-container .logo{margin: 0px; min-width: 350px;}

    .footer {flex-direction: column;}
    .footer .footer-content.left,
    .footer .footer-content.right{ width: 90%; margin: 20px auto;}
    .footer .footer-content.right{ justify-content: center;}

    .text-container .title,
    .show-case-container .show-case-description p.title,
    .order-container .title{font-size: var(--font-size-5)}
    .text-container .paragraph,
    .show-case-container .show-case-description p,
    .button{font-size: var(--font-size-4)}
}


@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .show-case-container .show-case-image{max-width: 300px !important;}
}
</style>
